import React, { Fragment, useEffect, useState } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import Loader from "../Loader";
import { Zoom } from "react-awesome-reveal";
import HomeIcon from "@mui/icons-material/Home";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { setLanguage } from "../data/languageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAfrica,
  faGlobeEurope,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
const LayoutCruises = () => {
  const language = useSelector((state) => state.language.language);
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      // Convert 70vh to pixels
      const triggerHeight = window.innerHeight * 0.2;
      const triggerHeight2 = window.innerHeight * 0.1;

      if (window.scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.scrollY > triggerHeight2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the scroll to top action
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu open and closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );

  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "ar" : "en";
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  return (
    <>
      <header>
        <div
          className={`header-area ${scrolled ? "sticky" : ""} `}
          id="sticky-header"
        >
          <div className="container-fulid">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-1">
                <div className="header-logo">
                  <Link to="/">
                    <img src="logo-white.png" alt="" />
                  </Link>
                </div>
              </div>
              <div
                className={`col-lg-7  ${
                  language === "en" ? "ms-auto" : "me-auto"
                }`}
              >
                <div className="header-menu">
                  <ul>
                    <li>
                      <NavLink to="/nile-capital-cruise">
                        {" "}
                        {language === "en" ? " Overview " : "نظرة عامة"}
                      </NavLink>
                    </li>
                    <li>
                      <Link to="/nile-capital-cruise/accommodation">
                        {language === "en" ? "Accommodation" : "الاقامة"}{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/nile-capital-cruise/dining">
                        {language === "en" ? "Dining" : "المطاعم و البارات"}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/nile-capital-cruise/facilities">
                        {language === "en" ? " Facilities " : " الترفية "}{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/nile-capital-cruise/itineraries">
                        {language === "en" ? "Itineraries" : "الرحلات"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/nile-capital-cruise/gallery">
                        {language === "en" ? "Gallery" : "المعرض"}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/nile-capital-cruise/contact-us">
                        {language === "en" ? "Contact Us" : "تواصل معنا"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 d-flex align-items-center justify-content-end">
                <Button
                  className="my-auto   lang-btn"
                  onClick={toggleLanguage}
                  style={{
                    backgroundColor: "transparent",
                    border: "0",
                    fontSize: "18px",
                    textShadow: "0 0 2px 1px #0A254C",
                    borderRadius: " 0%  ",

                    color: "#fff",
                  }}
                >
                  {language === "ar" ? (
                    <>
                      {" "}
                      <FontAwesomeIcon className="ps-2" icon={faGlobeEurope} />
                      {language === "en" ? "Ar" : "En"}
                    </>
                  ) : (
                    <>
                      {" "}
                      <FontAwesomeIcon className="pe-2" icon={faGlobeEurope} />
                      {language === "en" ? "Ar" : "En"}
                    </>
                  )}
                </Button>

                <Link
                  to="/"
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderLeft: language === "en" ? "1px solid #fff" : "0",
                    borderRight: language === "ar" ? "1px solid #fff" : "0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    style={{ fontSize: "18px" }}
                    className="text-light  px-3"
                  />
                </Link>
                {/* <div className="header-button text-right">
                  <Link to="/booking-search" style={{ fontFamily: "unset" }}>
                    {language === "en" ? (
                      <>
                        <i className="bi bi-calendar-check pe-2"></i> Book Now
                      </>
                    ) : (
                      <>
                        <i className="bi bi-calendar-check pe-2"></i> احجز الان
                      </>
                    )}
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="mobile-menu-area  d-sm-block d-md-block d-lg-none"
          style={{ position: "fixed", zIndex: "9999" }}
        >
          <div className={`mobile-menu mean-container ${isOpen ? "open" : ""}`}>
            <div className="mean-bar">
              <Link to="/">
                <img
                  src="logo-white.png"
                  alt=""
                  className="ms-2 mt-1"
                  width="65"
                  height="50"
                />
              </Link>
              <Link
                to="#."
                className="meanmenu-reveal meanclose"
                onClick={toggleMenu}
                style={{
                  right: 0,
                  left: "auto",
                  textAlign: "center",
                  textIndent: 0,
                  fontSize: 18,
                }}
              >
                {isOpen ? (
                  "X"
                ) : (
                  <>
                    <a
                      href="#nav"
                      className="meanmenu-reveal"
                      style={{ display: "contents" }}
                      onClick={() => toggleMenu()}
                    >
                      <span></span> <span></span> <span></span>
                    </a>
                  </>
                )}
              </Link>
              <nav className="mean-nav">
                <ul
                  className="nav_scroll"
                  style={{
                    maxHeight: !isOpen ? "0px" : "100vh",
                    zIndex: "99999999",
                    position: "relative",
                    overflow: "hidden",
                    transition: "max-height 0.5s linear",
                  }}
                >
                  <li>
                    <Link
                      to="/nile-capital-cruise"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? " Overview " : "نظرة عامة"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nile-capital-cruise/accommodation"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? " Accommodation " : " الاقامة  "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nile-capital-cruise/dining"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? " Dining " : " المطاعم والبارات "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nile-capital-cruise/facilities"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? " Facilities " : " الترفية "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nile-capital-cruise/itineraries"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? "Itineraries" : "الرحلات"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nile-capital-cruise/gallery"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? "Gallery" : "المعرض"}
                    </Link>
                  </li>
                  <li className="mean-last">
                    <Link
                      to="/nile-capital-cruise/contact-us"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {language === "en" ? "Contact Us" : "تواصل معنا"}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <div className="row  justify-content-center align-items-center">
                      <div className="col-12  d-block   px-0 py-0 text-center">
                        <Link
                          to="#."
                          className=" col-12 my-auto fs-4 py-2 "
                          onClick={toggleLanguage}
                          style={{
                            border: "0",
                            borderRight: "3px solid #000",
                            borderRadius: " 0%  ",
                            backgroundColor: "#D9B95E",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                          }}
                        >
                          {language === "ar" ? (
                            <>
                              {" "}
                              <FontAwesomeIcon icon={faGlobeAfrica} />
                              <span className="fs-3  px-2">
                                {language === "en" ? "Ar" : "En"}
                              </span>
                            </>
                          ) : (
                            <>
                              {" "}
                              <FontAwesomeIcon icon={faGlobeAfrica} />
                              <span className="fs-4 px-2">
                                {language === "en" ? "Ar" : "En"}
                              </span>
                            </>
                          )}
                        </Link>
                      </div>
                      {/* <div className="col-6  d-block mx-0 px-0 text-center">
                        <Link
                          className=" d-block mx-auto text-light fs-5  py-2  text-center"
                          to="/booking-search"
                          style={{
                            fontFamily: "unset",
                            backgroundColor: "#D9B95E",
                            fontSize: "12px",
                          }}
                        >
                          {language === "en" ? (
                            <>
                              <i className="bi bi-calendar-check pe-2"></i> Book
                              Now
                            </>
                          ) : (
                            <>
                              <i className="bi bi-calendar-check pe-2"></i> احجز
                              الان
                            </>
                          )}
                        </Link>
                      </div> */}
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mean-push"></div>
          </div>
        </div>
      </header>

      <Outlet />

      <footer>
        <Zoom duration="2000" delay="0" triggerOnce="true">
          <div className="footer-area home-five">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-logo">
                    <Link to="#.">
                      <img src="logo-footer.png" alt="" />
                    </Link>
                  </div>
                  <div className="footer-widget-desc">
                    <p>
                      {language === "en"
                        ? `Indulge in an exquisite and enchanting journey aboard the
                    Nile Capital Cruises in full luxury and style as you sail
                    along the timeless and awe-inspiring Nile River between
                    the magnificent cities of Luxor and Aswan.`
                        : `انغمس في رحلة رائعة وساحرة على متن الرحلات النيلية في نايل كابيتل بكل الفخامة والاناقة وأنت تبحر على طول نهر النيل الخالد والمذهل بين مدينتي الاقصر وأسوان الرائعتين.`}
                    </p>
                  </div>
                  <div className="footer-widget-social-icon">
                    {/* <ul>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li>
                           <li>
                            <Link to="#.">
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>
                         
                          <li>
                            <Link to="#.">
                              <i className="fab fa-youtube"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>
                        </ul> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget-content">
                    <div className="footer-widget-menu">
                      <div className="footer-widget-title">
                        <h4> {language === "en" ? "About Us" : "من نحن"}</h4>
                      </div>
                      <ul>
                        <li>
                          <Link to="/nile-capital-cruise">
                            {language === "en" ? " Overview " : "نظرة عامة"}
                          </Link>
                        </li>
                        <li>
                          <Link to="/nile-capital-cruise/accommodation">
                            {language === "en"
                              ? " Accommodation "
                              : " الاقامة  "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/nile-capital-cruise/dining">
                            {language === "en" ? "Dining" : "المطاعم والبارات"}{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/nile-capital-cruise/facilities">
                            {" "}
                            {language === "en" ? " Facilities " : " الترفية "}
                          </Link>
                        </li>

                        <li>
                          <Link to="/nile-capital-cruise/itineraries">
                            {language === "en" ? "Itineraries" : "الرحلات"}
                          </Link>
                        </li>
                        <li>
                          <Link to="/nile-capital-cruise/gallery">
                            {language === "en" ? "Gallery" : "المعرض"}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget-content">
                    <div className="footer-widget-title">
                      <h4>{language === "en" ? "General" : "روابط رئيسية"} </h4>
                    </div>
                    <div className="footer-widget-menu">
                      <ul>
                        <li>
                          <Link to="/">
                            {language === "en" ? " Home" : "الرئيسية"}{" "}
                          </Link>
                        </li>

                        <li>
                          <Link to="/sightseeing">
                            {" "}
                            {language === "en"
                              ? " Sightseeing"
                              : " المعالم "}{" "}
                          </Link>
                        </li>
                        <li>
                          <li>
                            <Link to="/nile-capital-cruise/contact-us">
                              {language === "en" ? "Contact Us" : "تواصل معنا"}
                            </Link>
                          </li>
                          <Link to="/terms-conditions">
                            {language === "en"
                              ? "Terms & Conditions"
                              : " الشروط والاحكام "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">
                            {language === "en"
                              ? "Privacy Policy"
                              : " سياسة الخصوصية "}{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="footer-widget-content">
                    <div className="footer-widget-title">
                      <h4>
                        {language === "en" ? "Newsletter" : "النشرة الإخبارية"}
                      </h4>
                    </div>
                    <p style={{ color: "#fff" }}>
                      {language === "en"
                        ? "Subscribe our Newsletter"
                        : " اشترك في النشرة الإخبارية لدينا "}
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="single-newsletter-box">
                        <input
                          type="email"
                          name="Email"
                          placeholder={
                            language === "en"
                              ? "Enter E-Mail"
                              : " ادخل البريد الالكتروني "
                          }
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit">
                          {" "}
                          {language === "en" ? "Subscribe Now" : "اشترك الان"}
                        </button>
                      </div>
                    </form>

                    {!loadingNewsletter && !errorNewsletter ? (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {language === "en"
                            ? " Thank you for subscribing!"
                            : "شكرا لك على الاشتراك!"}
                        </Alert>
                      </Snackbar>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row footer-bottom align-items-center  ">
                <div className="col-lg-6 col-md-6 right">
                  <div className="footer-bottom-content">
                    <p>
                      {language === "en"
                        ? "© 2024 All Rights Reserved Nile Capital Cruises"
                        : "© 2024 جميع الحقوق محفوظة لشركة نايل كابيتال للرحلات النيلية"}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="footer-bottom-content ">
                    <div className="footer-bottom-item  left">
                      <ul>
                        <li>
                          <Link to="https://titegypt.com" target="_blank">
                            Powered by T.I.T. Solutions
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      </footer>
    </>
  );
};

export default LayoutCruises;
